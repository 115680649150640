<template>
  <div class="uk-padding-large position-relative uk-grid" uk-grid="">
    <div class="uk-width-5-5">
      <div class="card mx-5">
        <!-- Card header -->
        <div class="card-header actions-toolbar border-0">
          <div class="d-flex justify-content-between align-items-center">
            <!-- <h4 class="d-inline-block mb-0"></h4> -->
            <div>
              <a href="#" class="btn btn-icon btn-hover btn-circle" aria-expanded="false">
                <i class="uil-search"></i>
              </a>
              <div class="uk-drop uk-open"  style="left: 166px;
    top: 56.297px;">
                <form class="uk-search uk-search-navbar uk-width-1-1">
                  <input class="uk-search-input shadow-0 uk-form-small" type="search" :placeholder="$t('general.search')" autofocus="" v-model="filter.search">
                </form>
              </div>
            </div>
            <div class="d-flex">
              <!-- Shorting -->
              <a href="#" class="btn btn-icon btn-hover  btn-circle" :uk-tooltip="$t('filters.sort_list')" title="" aria-expanded="false">
                <i class="uil-list-ui-alt"></i>
              </a>
              <div uk-dropdown="pos: bottom-right ; mode: click ;animation: uk-animation-scale-up" class="uk-dropdown">
                <ul class="uk-nav uk-dropdown-nav">
                  <li @click="sortOrder('name', 'asc')"><a href="javascript:void(0)">{{ $t('sorting.a_z') }}</a></li>
                  <li @click="sortOrder('name', 'desc')"><a href="javascript:void(0)">{{ $t('sorting.z_a') }}</a></li>
                  <li @click="sortOrder('created_at', 'asc')"><a href="javascript:void(0)"> {{ $t('sorting.created_at_asc') }}</a></li>
                  <li @click="sortOrder('created_at', 'desc')"><a href="javascript:void(0)"> {{ $t('sorting.created_at_desc') }}</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <!-- Table -->
        <div class="table-responsive">
          <table class="table align-items-center">
            <thead>
            <tr>
              <th scope="col">{{ $t('gift.name') }}</th>
              <th scope="col"> </th>
            </tr>
            </thead>
            <tbody class="list">
            <!--user item-->
            <tr v-for="gift in formattedItem">
              <th scope="row">
                <div class="media align-items-center">
                  <div>
                    <div class="avatar-parent-child">
                      <img :src="gift.image" class="avatar">
                    </div>
                  </div>
                  <div class="media-body ml-4">
                    <span  class="name h6 mb-0 text-sm">
                      {{gift.name}}
                    </span>
                  </div>
                </div>
              </th>
                <td class="text-right">
                  <!-- Actions -->
                  <div class="actions ml-3">
                    <template v-if="selected_items.includes(gift.id)">
                      <button
                        disabled=""
                        class="btn btn-sm btn-success"
                        style="color: #fff"
                      >
                        <i class="uil-check"></i>
                        Eklendi
                      </button>
                    </template>
                    <template v-else>
                      <button
                        @click="$emit('addNewGift', gift)"
                        class="btn btn-sm btn-primary"
                        style="color: #fff"
                      >
                        <i class="uil-plus"></i> Ekle
                      </button>
                    </template>
                  </div>
                </td>
            </tr>
            </tbody>
          </table>
        </div>

    <pagination v-if="pagination && pagination.hasOwnProperty('links')" :pagination="pagination" @changePage="setPage($event)"></pagination>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import DataTable from "@/assets/components/dataTable/DataTable";
import store from '@/core/services'
import Pagination from "@/assets/components/Pagination";
import gift_module, {
  BASE_URL as GIFT_BASE_URL,
  MODULE_NAME as GIFT_MODULE_NAME,
  ERROR as GIFT_ERROR,
  ITEMS as GIFT_ITEMS,
  GET_ITEMS as GIFT_GET_ITEMS,
  DELETE_ITEM as GIFT_DELETE_ITEM,
} from "@/core/services/store/gift.module";
import moment from "moment";
export default {
  name: "GiftForAssignment",
  components: {DataTable, Pagination}, 
  props: {
    selected_items: {
      required: true,
      type:Array,
      default:[]
    },
  },
  data() {
    return {
        status_id: null,
        pagination: null,
        activeItem: null,
        successMessage: null,
        errorMessages: [],
        page: 1,
        per_page: 10,
        sort_by: "id",
        sort_desc: "desc",
        filter: {
          search: null,
          orderBy: "id",
          sort: "desc",
        },
    }
  },
  beforeCreate() {
    function registerStoreModule(moduleName, storeModule) {
      if (!(store && store.state && store.state[moduleName])) {
        store.registerModule(moduleName, storeModule);
      }
    }
    registerStoreModule(GIFT_MODULE_NAME, gift_module);
  },
  computed: {
    items: {
      get() {
        return store.getters[GIFT_MODULE_NAME + "/" + GIFT_ITEMS];
      },
      set(value) {},
    },
    error: {
      get() {
        return store.getters[GIFT_MODULE_NAME + "/" + GIFT_ERROR];
      },
      set(value) {},
    },
    formattedItem() {
      let results = [];
      if (this.items === null || !("data" in this.items)) return [];
      this.pagination = this.items;

      this.items.data.forEach((currentItem) => {
        results.push({
          id: currentItem.id,
          name: currentItem.name,
          image: currentItem.image_link,
        });
      });

      return results;
    },

  },
  methods: {
    getGiftResult(){
      if (!this.$isGiftVisible) {
        return false;
      }
       let self = this
       this.$store.dispatch(GIFT_MODULE_NAME + "/" + GIFT_GET_ITEMS, {
        url: GIFT_BASE_URL,
        filters: {
          like: self.filter.search,
          page: self.page,
          per_page: self.per_page,
          sort: self.filter.sort,
          order_by: self.filter.orderBy,
          status_id: self.status_id,
        },
      });
    },
    removeGift(id){
      this.$store.dispatch(GIFT_MODULE_NAME + "/" + GIFT_DELETE_ITEM, {
        url: GIFT_BASE_URL + "/" + id,
      }).then(()=>{
          if (!this.error) {
            this.successMessage = this.$t("general.successfully_deleted");
            this.getGiftResult()
          }else this.errorMessages.push(this.error)
      })
    },
    sortOrder(orderBy, sort, is_user = true) {
      let self = this;
      self.page = 1;
      self.filter.orderBy = orderBy;
      self.filter.sort = sort;

      this.getGiftResult();
    },
    setPage(page, is_user = true) {
      let self = this;
      self.page = page;
      this.getGiftResult();
    },
    resetMessages(){
      this.successMessage = null
      this.errorMessages = []
    }
  },
  mounted() {
    this.getGiftResult();
  },
  watch: {
    'filter.search': function (val) {
      this.debouncedFilterSearch()
    },
    successMessage: function (val) {
      this.debouncedResetValues();
    },
    errorMessages: function (val) {
      this.debouncedResetValues();
    },
  },
  created: function () {
    this.debouncedFilterSearch = _.debounce(this.getGiftResult, 500)
    this.debouncedResetValues = _.debounce(this.resetMessages, 2000);
  },
};
</script>
<style scoped>
input[type="button"], input[type="submit"], span.btn, .btn{
  display: inline-block;
}
</style>