<template>
  <div class="row justify-content-center" style="width:100%">
    <div class="col-xl-12">
      <div style="position: absolute; right: 40px;font-size: 1rem;top: -2px;">
        {{ this.$t('general.number_of_selected_users') }}: <i class="uil-users-alt"></i>
        {{ selectedUsersCount }}
      </div>
      <!-- id= notification makes error-->
      <!-- <ul id="notifications" class="uk-tab page-tab" uk-switcher="animation: uk-animation-fade"> -->

      <ul class="uk-tab page-tab" uk-switcher="animation: uk-animation-fade">
        <li>
          <a href="#">
            <h4>
              <i class="uil-user"></i> Kullanıcılar ({{ user_items.total }})
            </h4>
          </a>
        </li>
        <li>
          <a href="#">
            <h4 class="text-primary">
              <i class="uil-users-alt"></i> Kullanıcı Grupları ({{
                group_items.total
              }})
            </h4>
          </a>
        </li>
        <li v-if="case_detail === 'usersRequestedTraining'">
          <a href="#">
            <h4 class="text-primary">
              <i class="uil-users-alt"></i> Talepte Bulunanlar ({{
                userRequestedTraining.total
              }})
            </h4>
          </a>
        </li>
      </ul>

      <ul class="uk-switcher">
        <!--tab:  kullanicilar listesi-->
        <li>
          <div class="card-header actions-toolbar border-0 pb-0">
            <div class="d-flex justify-content-between align-items-center">
              <div class="row" style="width: 100%">
                <div class=" col-md-1  search-icon ">
                  <i class="uil-search"></i>
                </div>
                <div class="col-md-11 uk-search uk-search-navbar" style="margin-left:-2rem">
                  <input class="uk-search-input shadow-0 uk-form-small " type="search" v-model="user_filters.search"
                    :placeholder="$t('general.first_name_and_last_name_or_id_no')"
                    v-on:keydown.enter.prevent="triggerUserFilterSearch" />
                </div>
                <!-- <a href="javascript:;" class="btn btn-icon btn-hover btn-circle">
                  <i class="uil-search"></i>
                </a>
                <div class="uk-drop" id="userSearchInput" uk-drop="mode: click,hover; pos: right-center; offset: 0">
                  <form class="uk-search uk-search-navbar uk-width-1-1">
                    <input
                        class="uk-search-input shadow-0 uk-form-small"
                        type="search"
                        v-model="user_filters.search"
                        :placeholder="$t('general.first_name_and_last_name_or_id_no')"
                        v-on:keydown.enter.prevent='triggerUserFilterSearch'
                    />
                  </form>
                </div> -->
              </div>
              <div class="d-flex">
                <!-- Shorting -->
                <a href="#" class="btn btn-icon btn-hover btn-circle" :uk-tooltip="$t('general.sort_list')">
                  <i class="uil-list-ui-alt"></i>
                </a>

                <div uk-dropdown="pos: bottom-right ; mode: click ;animation: uk-animation-scale-up" class="uk-dropdown">
                  <ul class="uk-nav uk-dropdown-nav">
                    <li class="uk-active">
                      <a href="javascript:void(0)" @click="sortOrder('name', 'asc', 'user_filters')">{{
                        $t("filters.order.a_z") }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0)" @click="sortOrder('name', 'desc', 'user_filters')">{{
                        $t("filters.order.z_a") }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0)" @click="sortOrder('created_at', 'asc', 'user_filters')">
                        {{ $t("filters.order.created_at_asc") }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0)" @click="sortOrder('created_at', 'desc', 'user_filters')">
                        {{ $t("filters.order.created_at_desc") }}</a>
                    </li>
                  </ul>
                </div>

                <!-- Filter -->
                <a href="#" class="btn btn-icon btn-hover btn-circle" :uk-tooltip="$t('general.filter_list')">
                  <i class="uil-filter"></i>
                </a>
                <UserFilter @getFilter="filterUsers($event)" ref="childComponentRef" :page=1
                  :show_only_unassigned_users="show_only_unassigned_users"></UserFilter>
              </div>
            </div>
          </div>
          <div class="uk-card-header pl-0 pr-0">
            <div class="float-left d-flex">
              <button type="button" class="btn btn-sm btn-soft-secondary mr-2" @click="allUserToggle()">
                <i class="uil-check-square"></i> {{ selectAllUserText }}
              </button>
            </div>
            <div class="float-right d-flex">
              <div class="btn-group ml-3">
                <select class="ml-1" tabindex="-98" @change="filterUsers()" v-model="user_filters.per_page">
                  <option value="10">10</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                  <option value="500">500</option>
                  <option value="1000">1000</option>
                  <option value="2500">2500</option>
                </select>
              </div>
            </div>
          </div>

          <!-- Table -->
          <div class="table-responsive users-table">
            <table class="table align-items-center">
              <thead>
                <tr>
                  <th scope="col">{{ this.$t('general.first_name_and_last_name') }}</th>
                  <th scope="col">{{ this.$t('general.title_position') }}</th>
                  <th scope="col">Grubu</th>
                  <th scope="col">{{ this.$t('general.added_at') }}</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody class="list">
                <!--user item-->
                <tr v-for="(          item, index          ) in           formattedUsers          ">
                  <th scope="row">
                    <div class="media align-items-center">
                      <div class="form-group mb-0" style="display: block; position: relative;">
                        <input type="checkbox" name="" v-model="checked_users" :value="item.id"
                          @click="userChangeCheckItem" :id="'user_checkbox_' + item.id" style="
                                        width: 15px;
                                        height: 15px;
                                        margin: 0;
                                        display: block;
                                        position: relative;
                                        z-index: 9999;
                                      " />
                      </div>
                      <div style="display:block; position: relative">
                        <div class="avatar-parent-child" style="width: max-content">
                          <img alt="Image placeholder" :src="item.photo_link" class="avatar rounded-circle" />
                          <span :class="item.is_online ? 'bg-success' : 'bg-offline'
                            " class="avatar-child avatar-badge"></span>
                        </div>
                      </div>
                      <div class="media-body ml-4">
                        <span class="name h6 mb-0 text-sm">{{
                          item.name + " " + item.surname
                        }}</span>
                        <small class="d-block font-weight-bold">#{{ item.id }}</small>
                      </div>
                    </div>
                  </th>
                  <td>{{ item.title }}</td>
                  <td>{{ item.group }}</td>
                  <td>{{ item.created_at }}</td>
                  <td class="text-right">
                    <!-- Actions -->
                    <div class="actions ml-3">
                      <a uk-toggle="target: #modal-profile" @click="user_filters.activeItem = item"
                        class="btn btn-icon btn-hover btn-sm btn-circle" uk-tooltip="Kullanıcının Profili">
                        <i class="uil-user"></i>
                      </a>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <pagination :pagination="user_filters.pagination" @changePage="setPage($event, 'user_filters')">
            </pagination>
            <UserProfile v-if="formattedUserDetail && user_filters.activeItem" :item="user_filters.activeItem"
              :user_detail="formattedUserDetail"></UserProfile>
          </div>
        </li>

        <!--tab: gruplar listesi-->
        <li>
          <!-- Card header -->
          <div class="card-header actions-toolbar border-0 pb-0">
            <div class=" d-flex justify-content-between align-items-center">
              <div class="row" style="width: 100%">
                <div class=" col-md-1  search-icon ">
                  <i class="uil-search"></i>
                </div>
                <div class="col-md-11 uk-search uk-search-navbar" style="margin-left:-2rem">
                  <input class="uk-search-input shadow-0 uk-form-small" type="search" v-model="group_filters.search"
                    placeholder="Grud Adı" v-on:keydown.enter.prevent="triggerGroupFilterSearch" />
                </div>
              </div>
              <div class="d-flex">
                <!-- Shorting -->
                <a href="#" class="btn btn-icon btn-hover btn-circle" :uk-tooltip="$t('general.sort_list')">
                  <i class="uil-list-ui-alt"></i>
                </a>
                <div uk-dropdown="pos: bottom-right ; mode: click ;animation: uk-animation-scale-up" class="uk-dropdown">
                  <ul class="uk-nav uk-dropdown-nav">
                    <li class="uk-active">
                      <a href="javascript:void(0)" @click="sortOrder('name', 'asc', 'group_filters')">{{
                        $t("filters.order.a_z") }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0)" @click="sortOrder('name', 'desc', 'group_filters')">{{
                        $t("filters.order.z_a") }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0)" @click="sortOrder('created_at', 'asc', 'group_filters')">
                        {{ $t("filters.order.created_at_asc") }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0)" @click="sortOrder('created_at', 'desc', 'group_filters')
                        ">
                        {{ $t("filters.order.created_at_desc") }}</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="uk-card-header pl-0 pr-0">
            <div class="float-left d-flex">
              <button type="button" @click="allGroupToggle(all_group_checked)" class="btn btn-sm btn-soft-secondary mr-2">
                <i class="uil-check-square"></i> {{ selectAllGroupText }}
              </button>
              <!-- <button
                type="button"
                class="btn btn-sm btn-soft-success"
              >
                <i class="uil-check-circle"></i> İşaretlenenleri
                Ekle
              </button> -->
            </div>
          </div>

          <!-- Table -->
          <div class="table-responsive users-table">
            <table class="table align-items-center">
              <thead>
                <tr>
                  <th scope="col">
                    {{ $t("user_group.list.name") }}
                  </th>
                  <th scope="col">
                    {{ $t("user_group.list.user_count") }}
                  </th>
                  <th scope="col">
                    {{ $t("general.created_at") }}
                  </th>
                </tr>
              </thead>
              <tbody class="list">
                <!--group item-->
                <tr v-for="(          item, index          ) in           formattedGroupItems          ">
                  <th scope="row">
                    <div class="media align-items-center">
                      <input type="checkbox" v-model="checked_groups" :value="item.id" @click="groupChangeCheckItem"
                        :id="'group_checkbox_' + item.id" style="
                                      width: 15px;
                                      margin: 10px 10px 10px 0px;
                                    " />
                      <div>
                        <div class="avatar-parent-child">
                          <i class="icon-feather-users"></i>
                          <span class="
                                          avatar-child avatar-badge
                                          bg-success
                                        "></span>
                        </div>
                      </div>
                      <div class="media-body ml-4">
                        <a @click="$router.push({
                          name: 'company.user.group.edit',
                          params: { id: item.id },
                        })
                          " class="name h6 mb-0 text-sm">{{ item.name }}</a>
                        <small class="d-block font-weight-bold">#{{ item.id }}</small>
                      </div>
                    </div>
                  </th>
                  <td>{{ item.count }}</td>
                  <td>{{ item.created_at }}</td>
                </tr>
              </tbody>
            </table>
            <pagination :pagination="group_filters.pagination" @changePage="setPage($event, 'group_filters')">
            </pagination>
          </div>
        </li>
        <!--users who requested trainings-->

        <li v-if="case_detail === 'usersRequestedTraining'">
          <div class="card-header actions-toolbar border-0 pb-0" style="margin-bottom:10px">
            <div class="d-flex justify-content-between align-items-center">
              <div class="row" style="width: 100%">
                <div class=" col-md-1  search-icon ">
                  <i class="uil-search"></i>
                </div>
                <div class="col-md-11 uk-search uk-search-navbar" style="margin-left:-2rem">
                  <input class="uk-search-input shadow-0 uk-form-small " type="search" v-model="user_filters.search"
                    :placeholder="$t('general.first_name_and_last_name_or_id_no')"
                    v-on:keydown.enter.prevent="triggerUserFilterSearch" />
                </div>
              </div>
              <div class="d-flex">
                <!-- Shorting -->
                <a href="#" class="btn btn-icon btn-hover btn-circle" :uk-tooltip="$t('general.sort_list')">
                  <i class="uil-list-ui-alt"></i>
                </a>

                <div uk-dropdown="pos: bottom-right ; mode: click ;animation: uk-animation-scale-up" class="uk-dropdown">
                  <ul class="uk-nav uk-dropdown-nav">
                    <li class="uk-active">
                      <a href="javascript:void(0)" @click="sortOrder('name', 'asc', 'user_filters')">{{
                        $t("filters.order.a_z") }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0)" @click="sortOrder('name', 'desc', 'user_filters')">{{
                        $t("filters.order.z_a") }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0)" @click="sortOrder('created_at', 'asc', 'user_filters')">
                        {{ $t("filters.order.created_at_asc") }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0)" @click="sortOrder('created_at', 'desc', 'user_filters')">
                        {{ $t("filters.order.created_at_desc") }}</a>
                    </li>
                  </ul>
                </div>

                <!-- Filter -->
                <a href="#" class="btn btn-icon btn-hover btn-circle" :uk-tooltip="$t('general.filter_list')">
                  <i class="uil-filter"></i>
                </a>
                <!-- <UserFilter @getFilter="filterUsers($event)" ref="childComponentRef"></UserFilter> -->
              </div>
            </div>
          </div>

          <!-- Table -->
          <div class="table-responsive users-table">
            <table class="table align-items-center">
              <thead>
                <tr>
                  <th scope="col">{{ this.$t('general.first_name_and_last_name') }}</th>
                  <th scope="col">{{ this.$t('general.title_position') }}</th>
                  <th scope="col">Grubu</th>
                  <th scope="col">{{ this.$t('general.added_at') }}</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody class="list">
                <!--user item-->
                <tr v-for="(          item, index          ) in           formattedUserRequested          ">
                  <th scope="row">
                    <div class="media align-items-center">

                      <div style="display:block; position: relative">
                        <div class="avatar-parent-child" style="width: max-content">
                          <img alt="Image placeholder" :src="item.photo_link" class="avatar rounded-circle" />
                          <span :class="item.is_online ? 'bg-success' : 'bg-offline'
                            " class="avatar-child avatar-badge"></span>
                        </div>
                      </div>
                      <div class="media-body ml-4">
                        <span class="name h6 mb-0 text-sm">{{
                          item.name + " " + item.surname
                        }}</span>
                        <small class="d-block font-weight-bold">#{{ item.id }}</small>
                      </div>
                    </div>
                  </th>
                  <td>{{ item.title }}</td>
                  <td>{{ item.group }}</td>
                  <td>{{ item.created_at }}</td>
                  <td class="text-right">
                    <!-- Actions -->
                    <div class="actions ml-3">
                      <a uk-toggle="target: #modal-profile" @click="user_filters.activeItem = item"
                        class="btn btn-icon btn-hover btn-sm btn-circle" uk-tooltip="Kullanıcının Profili">
                        <i class="uil-user"></i>
                      </a>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <pagination :pagination="userRequestedTraining.pagination" @changePage="setPage($event, 'user_filters')">
            </pagination>
            <UserProfile v-if="formattedUserDetail && user_filters.activeItem" :item="user_filters.activeItem"
              :user_detail="formattedUserDetail"></UserProfile>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import store from "@/core/services";
import moment from "moment";
import Pagination from "@/assets/components/Pagination";
import UserProfile from "@/view/company/User/UserProfile";
import UserFilter from "@/view/company/User/Filter";

import user_module, {
  BASE_URL as USER_BASE_URL,
  GET_ITEMS as USER_GET_ITEMS,
  ITEMS as USER_ITEMS,
  MODULE_NAME as USER_MODULE_NAME,
} from "@/core/services/store/user.module";

import user_detail_module, {
  GET_ITEM as GET_USER_DETAIL,
  ITEM as USER_DETAIL,
  MODULE_NAME as USER_DETAIL_MODULE,
} from "@/core/services/store/user_detail.module";

import group_module, {
  BASE_URL as GROUP_BASE_URL,
  GET_ITEMS as GROUP_GET_ITEMS,
  ITEMS as GROUP_ITEMS,
  MODULE_NAME as GROUP_MODULE_NAME,
} from "@/core/services/store/user_group.module";

import company_module, {
  MODULE_NAME as COMPANY_MODULE,
  ONLINE_USERS,
} from "@/core/services/store/company.module";
import { GET_ITEMS as GET_USER } from "@/core/services/store/REST.module";

const _USER_MODULE = USER_MODULE_NAME;
const _GROUP_MODULE = GROUP_MODULE_NAME;
const _USER_DETAIL_MODULE = USER_DETAIL_MODULE;
const _COMPANY_MODULE = COMPANY_MODULE;
export default {
  name: "UserGroupSelection",
  components: {
    UserProfile,
    UserFilter,
    Pagination,
  },
  props: {
    assignment_id: {
      required: false,
      default: null,
    },
    added_users: {
      required: false,
      default: () => {
        return [];
      },
    },
    added_groups: {
      required: false,
      default: () => {
        return [];
      },
    },
    with_description: {
      required: false,
      default: false,
    },
    allow_both_selection: {
      required: false,
      default: false,
    },
    case_detail: {
      required: false,
      default: null,
    },
    checked_training: {
      required: false,
      default: null,
    },
    show_only_unassigned_users: false

  },
  data() {
    return {
      all_user_checked: true,
      all_group_checked: true,
      selectAllUserText: "Tümünü İşaretle",
      selectAllGroupText: "Tümünü İşaretle",
      checked_users: [],
      checked_groups: [],
      checked_user_page: [],
      temp_page: [],
      user_filters: {
        pagination: null,
        activeItem: null,
        page: 1,
        per_page: 10,
        search: null,
        order: "desc",
        sort: "id",
      },
      group_filters: {
        pagination: null,
        activeItem: null,
        page: 1,
        per_page: 10,
        search: null,
        order: "desc",
        sort: "id",
      },
      userRequestedTraining: {},
      lastFilter: null
    };
  },
  beforeCreate() {
    function registerStoreModule(moduleName, storeModule) {
      if (!(store && store.state && store.state[moduleName])) {
        store.registerModule(moduleName, storeModule);
      }
    }

    registerStoreModule(_USER_MODULE, user_module);
    registerStoreModule(_GROUP_MODULE, group_module);
    registerStoreModule(_USER_DETAIL_MODULE, user_detail_module);
    registerStoreModule(_COMPANY_MODULE, company_module);
  },
  computed: {
    onlineUsers() {
      return store.getters[_COMPANY_MODULE + "/" + ONLINE_USERS];
    },
    user_detail: {
      get() {
        return store.getters[USER_DETAIL_MODULE + "/" + USER_DETAIL];
      },
      set(value) { },
    },
    user_items: {
      get() {
        return store.getters[USER_MODULE_NAME + "/" + USER_ITEMS];
      },
      set(value) { },
    },
    group_items: {
      get() {
        return store.getters[GROUP_MODULE_NAME + "/" + GROUP_ITEMS];
      },
      set(value) { },
    },
    error: {
      get() {
        return store.getters[ASSIGNMENT_MODULE_NAME + "/" + ASSIGNMENT_ERROR];
      },
      set(value) { },
    },
    formattedUsers() {
      let results = [];
      if (this.user_items === null || !("data" in this.user_items)) return [];
      this.user_filters.pagination = this.user_items;
      this.user_items.data.forEach((item) => {
        results.push({
          id: item.id,
          group: item.groups && item.groups.length ? item.groups[0].name : "-",
          name: item.name,
          surname: item.surname,
          dob: item.dob ? item.dob : "-",
          email: item.email,
          phone: item.phone,
          company_id: item.company_id,
          position_id: item.position_id,
          title_id: item.title_id,
          region_id: item.region_id,
          department_id: item.department_id,
          branch_id: item.branch_id,
          status_id: item.status_id,
          photo_link: item.photo_link,
          is_online: this.onlineUsers.find(
            (onlineUser) => onlineUser.user == item.id && onlineUser.status
          )
            ? true
            : false,
          fullname: [item.name, item.surname].join(" "),
          title: item.title ? item.title.name : "-",
          created_at: item.created_at
            ? moment(item.created_at).format("DD/MM/YYYY HH:mm:ss")
            : "-",
          last_login_at: item.last_login_at
            ? moment(item.last_login_at).format("DD/MM/YYYY HH:mm:ss")
            : "-",
        });
      });
      return results;
    },
    formattedUserDetail() {
      if (this.user_detail === null) return null;
      return this.user_detail;
    },
    formattedGroupItems() {
      let results = [];
      if (this.group_items === null || !("data" in this.group_items)) return [];
      this.group_filters.pagination = this.group_items;

      for (let i = 0; i < this.group_items.data.length; i++) {
        let currentItem = this.group_items.data[i];
        if (currentItem) {
          results.push({
            id: currentItem.id,
            name: currentItem.name,
            status_id: currentItem.status,
            count: currentItem.count,
            created_at: currentItem.created_at
              ? moment(currentItem.created_at).format("DD/MM/YYYY HH:mm:ss")
              : "-",
          });
        }
      }
      return results;
    },
    selectedUsersCount() {
      let userCount = this.checked_users.length;
      let userGroupCount = _.sumBy(
        _.values(
          this.formattedGroupItems.filter((item) =>
            this.checked_groups.includes(item.id)
          )
        ),
        "count"
      );
      return userCount + userGroupCount;
    },
    formattedUserRequested() {
      let results = [];
      if (this.userRequestedTraining === null || !("data" in this.userRequestedTraining)) return [];
      this.userRequestedTraining.pagination = this.userRequestedTraining;
      this.userRequestedTraining.data.forEach((item) => {
        results.push({
          id: item.id,
          group: item.groups && item.groups.length ? item.groups[0].name : "-",
          name: item.name,
          surname: item.surname,
          dob: item.dob ? item.dob : "-",
          email: item.email,
          phone: item.phone,
          company_id: item.company_id,
          position_id: item.position_id,
          title_id: item.title_id,
          region_id: item.region_id,
          department_id: item.department_id,
          branch_id: item.branch_id,
          status_id: item.status_id,
          photo_link: item.photo_link,
          is_online: this.onlineUsers.find(
            (onlineUser) => onlineUser.user == item.id && onlineUser.status
          )
            ? true
            : false,
          fullname: [item.name, item.surname].join(" "),
          title: item.title ? item.title.name : "-",
          created_at: item.created_at
            ? moment(item.created_at).format("DD/MM/YYYY HH:mm:ss")
            : "-",
          last_login_at: item.last_login_at
            ? moment(item.last_login_at).format("DD/MM/YYYY HH:mm:ss")
            : "-",
        });
      });
      return results;
    }
  },
  methods: {
    triggerUserFilterSearch() {
      this.debouncedFilterUserSearch();
    },
    triggerGroupFilterSearch() {
      this.debouncedFilterGroupSearch();
    },
    linkClick() {
      if (this.is_locked && !this.is_completed && !this.is_unlocked)
        return false;
      this.$emit("linkClick");
    },
    setPage(page, filter_name) {
      this[filter_name].page = page;

      let found = this.checked_user_page.find((element) => element == page);
      if (found) {
        this.selectAllUserText = "Tümünü Kaldır";
      } else {
        this.selectAllUserText = "Tümünü İşaretle";
      }
      if (filter_name === "user_filters") this.filterUsers();
      if (filter_name === "group_filters") this.filterGroups();
    },
    allUserToggle() {
      let self = this;
      this.userChangeCheckItem();
      let found = this.checked_user_page.find(
        (element) => element == this.user_items.current_page
      );

      if (
        this.temp_page.find((r) => r === this.user_items.current_page) ==
        undefined
      ) {
        this.formattedUsers.forEach((item) => {
          $("#user_checkbox_" + item.id).prop("checked", true);

          let checked_status = this.checked_users.find((r) => r === item.id);

          if (!checked_status) self.checked_users.push(item.id);
        });

        if (!found) {
          this.checked_user_page.push(this.user_items.current_page);
        }

        this.selectAllUserText = "Tümünü Kaldır";
        this.temp_page.push(this.user_items.current_page);
      } else {
        this.formattedUsers.forEach((item) => {
          $("#user_checkbox_" + item.id).prop("checked", false);
          this.checked_users = this.checked_users.filter(function (
            checked_user
          ) {
            return checked_user !== item.id;
          });
        });
        this.checked_user_page = this.checked_user_page.filter(function (
          checked_page
        ) {
          return checked_page !== found;
        });
        this.selectAllUserText = "Tümünü İşaretle";
        this.temp_page.forEach((e, i) => {
          if (e == self.user_items.current_page) {
            self.temp_page.splice(i, 1);
          }
        });
      }
    },
    sortOrder(sort, order, filter_name) {
      this[filter_name].page = 1;
      this[filter_name].order = order;
      this[filter_name].sort = sort;
      if (filter_name == "user_filters") this.filterUsers();
      if (filter_name == "group_filters") this.filterGroups();
    },
    allGroupToggle(val) {
      this.checked_groups = [];
      this.groupChangeCheckItem();
      if (val) {
        this.formattedGroupItems.forEach((item) => {
          $("#group_checkbox_" + item.id).prop("checked", true);
          this.checked_groups.push(item.id);
        });
        this.selectAllGroupText = "Tümünü Kaldır";
        this.all_group_checked = false;
      } else {
        this.formattedGroupItems.forEach((item) => {
          $("#group_checkbox_" + item.id).prop("checked", false);
        });
        this.selectAllGroupText = "Tümünü İşaretle";
        this.all_group_checked = true;
      }
    },
    getUserDetail(id) {
      store.dispatch(USER_DETAIL_MODULE + "/" + GET_USER_DETAIL, {
        url: "api/user/detail" + "/" + id,
      });
    },
    filterUsers(filter = null) {
      let self = this.user_filters;
      let filters = {
        like: self.search,
        page: filter ? 1 : self.page,
        per_page: self.per_page,
        order: self.order,
        sort: self.sort,
        status_id: 1,
        with: "groups:name",
      };
      !filter ? filter = this.lastFilter : this.lastFilter = filter;
      $.each(filter, function (key, value) {
        if (value && Array.isArray(filter[key])) {
          let ids = [];
          filter[key]?.forEach((items) => {
            ids.push(items.id);
          });
          filters[key] = JSON.stringify(ids)
        }
      });
      // this.lastFilter = filter;
      // }
      if (this.$refs.childComponentRef.filter.show_only_unassigned_users) {
        filters['assignment_id'] = this.assignment_id;
        filters['show_only_unassigned_users'] = true;

      }
      this.$store.dispatch(USER_MODULE_NAME + "/" + USER_GET_ITEMS, {
        url: USER_BASE_URL,
        filters: filters,
      });
      // const modal = UIkit.dropdown("#filterDropdown");
      // if (modal) modal.hide();
    },
    filterGroups(filter) {
      let self = this.group_filters;
      let filters = {
        like: self.search,
        page: self.page,
        per_page: self.per_page,
        order_by: self.sort,
        sort: self.order,
        status_id: 1,
      };
      this.$store.dispatch(GROUP_MODULE_NAME + "/" + GROUP_GET_ITEMS, {
        url: GROUP_BASE_URL,
        filters: filters,
      });
    },
    groupChangeCheckItem() {
      if (this.allow_both_selection) return;
      // this.checked_users = [];
      this.selectAllUserText = "Tümünü İşaretle";
      this.all_user_checked = true;
    },
    userChangeCheckItem() {
      if (this.allow_both_selection) return;
      // this.checked_groups = [];
      this.selectAllGroupText = "Tümünü İşaretle";
      this.all_group_checked = true;
    },
    updateCheckedItems() {
      if (this.added_users.length) {
        this.checked_users = this.added_users;
      }
      if (this.added_groups.length) {
        this.checked_groups = this.added_groups;
      }
    },
  },
  mounted() {
    this.filterUsers();
    this.filterGroups();
    this.updateCheckedItems();
    this.debouncedFilterUserSearch = _.debounce(this.filterUsers, 500);
    this.debouncedFilterGroupSearch = _.debounce(this.filterGroups, 500);
  },
  beforeDestroy() {
    const modal = UIkit.modal("#modal-profile");
    if (modal) modal.$destroy(true);
  },
  watch: {
    checked_training(val) {
      if (this.case_detail === 'usersRequestedTraining') {
        let self = this.user_filters;
        let filters = {
          like: self.search,
          page: self.page,
          per_page: self.per_page,
          order: self.order,
          sort: self.sort,
          status_id: 1,
          with: "groups:name",
          can_show_requested_trainings: true,
          training_id: val
        };
        this.$store.dispatch(GET_USER, {
          url: "api/user",
          filters: filters,
          acceptPromise: true,
        })
          .then((result) => {
            if (result?.data) {
              this.userRequestedTraining = result.data;
            }
          });
      }
    },
    "user_filters.activeItem"(value) {
      if (value) {
        this.getUserDetail(value.id);
      }
    },
    added_users(val) {

      if (val.length) this.checked_users = val;
    },
    added_groups(val) {
      if (val.length) this.checked_groups = val;
    },
    "user_filters.search"() {
      this.user_filters.page = 1;

      this.debouncedFilterUserSearch();
    },
    "group_filters.search"() {
      this.debouncedFilterGroupSearch();
    },
    checked_users(val) {
      if (this.with_description) {
        let items = [];
        this.formattedUsers.forEach((item) => {
          if (val.includes(item.id)) items.push(item);
        });
        this.$emit("setCheckedUsers", { ids: val, items: items });
        return;
      }
      this.$emit("setCheckedUsers", val);
    },
    checked_groups(val) {
      if (this.with_description) {
        let items = [];
        this.formattedGroupItems.forEach((item) => {
          if (val.includes(item.id)) items.push(item);
        });
        this.$emit("setCheckedGroups", { ids: val, items: items });
        return;
      }
      this.$emit("setCheckedGroups", val);
    },
  },
};
</script>
<style scoped>
input[type="checkbox"]:focus {
  box-shadow: none !important;
}

.bg-offline {
  background-color: #c0c0c0 !important;
}
</style>
