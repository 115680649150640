<template>
  <div id="qrCodeModal" :class="modal_name ? 'p-4' : 'px-4 pt-5 pb-4'">
    <button
        v-if="modal_name && showCloseBtn"
        class="uk-modal-close-default"
        type="button"
        uk-close
        @click="closeModal"
    ></button>
    <div class="section-header border-0 uk-flex-middle">
      <div class="section-header-left">
        <h2 class="uk-heading-line text-left">
          <span> {{ modal_title }} </span>
        </h2>
      </div>
    </div>

    <div v-if="successMessage" class="uk-alert-success" uk-alert>
      <a class="uk-alert-close" uk-close></a>
      <p style="color: green">
        <i class="uil-exclamation-triangle"></i> {{ successMessage }}
      </p>
    </div>
    <!-- Card header -->
    <div id="qrCode" class="card-body actions-toolbar border-0">
      <qr-code :text="qr_text"></qr-code>
    </div>
    <div class="card-footer d-flex justify-content-center p-2">
      <button
          v-if="modal_name"
          @click="downloadQrCode"
          class="btn btn-sm btn-secondary button btn-pill cursor-pointer mr-2">
        {{ $t("general.download") }}
      </button>

      <button
          @click="onSubmit"
          class="btn btn-sm btn-primary button btn-pill cursor-pointer"
          v-if="!hideSubmit">
        {{ $t("general.submit") }}
      </button>
    </div>
  </div>
</template>

<script>

export default {
  components: {},
  name: "QrCodeModal",
  props: {
    qr_text: {
      required: true,
    },
    modal_name: {
      required: true,
    },
    modal_title: {
      required: false,
    },
    hideSubmit: {
      required: false,
      default: false,
    },
    showCloseBtn: {
      required: false,
      default: true,
    }
  },
  data() {
    return {
      errorMessages: [],
      successMessage: null,
    };
  },
  methods: {
    downloadQrCode() {
      const qrCode = document.getElementById("qrCode");
      const canvas = qrCode.getElementsByTagName("canvas").item(0);

      const pngUrl = canvas
          .toDataURL("image/png")
          .replace("image/png", "image/octet-stream");

      let downloadLink = document.createElement("a");
      downloadLink.href = pngUrl;
      downloadLink.download = "lms-qr-code.png";

      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    },
    showErrors(errors) {
      this.resetMessages();
      for (const [field, error] of Object.entries(errors)) {
        if (Array.isArray(error)) {
          error.forEach((text) => {
            this.errorMessages.push(text);
          });
        } else this.errorMessages.push(error);
      }
    },
    closeModal() {
      if (this.modal_name) {
        this.$modal.hide(this.modal_name);

        this.successMessage = this.$t("general.successfully_created");
        this.$router.push({name: "company.assignments.list"});
      }
    },
    resetMessages() {
      this.errorMessages = [];
    },
  },
  watch: {
    errorMessages() {
      this.debouncedResetValues();
    },
  },
  mounted() {
  },
  created: function () {
    this.debouncedResetValues = _.debounce(this.resetMessages, 2500);
  },
};
</script>
<style scoped>
#qrCode {
  max-height: 500px;
  overflow-y: scroll;
  display: flex;
  justify-content: center;
}

.line-height-1 {
  line-height: 1rem;
}
</style>

