import { render, staticRenderFns } from "./Add.vue?vue&type=template&id=277963a7&scoped=true&xmlns=http%3A%2F%2Fwww.w3.org%2F1999%2Fhtml&"
import script from "./Add.vue?vue&type=script&lang=js&"
export * from "./Add.vue?vue&type=script&lang=js&"
import style0 from "./Add.vue?vue&type=style&index=0&id=277963a7&prod&scoped=true&lang=css&"
import style1 from "./Add.vue?vue&type=style&index=1&id=277963a7&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "277963a7",
  null
  
)

export default component.exports